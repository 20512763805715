import React, {
  useEffect,
  useState,
  useRef,
  useContext,
  useReducer,
} from "react";
import { UserContext } from "../UserContext";
import axios from "axios";

import useFirebase from "../Hooks/useFirebase";
import useDBAuth from "../Hooks/useDBAuth";

import { Table, Button } from "@mantine/core";
import Page_Login from "./Page_Login";
import Comp_UserProfile from "../Component/Comp_UserProfile";

const { REACT_APP_ECLIPSE_API_PUBLIC, REACT_APP_ECLIPSE_API } = process.env;

const Page_CafeEvents = (props) => {
  // --------------------------------------- use context
  const contextValue = useContext(UserContext);
  // --------------------------------------- auth User
  const [firebase_Status, firebase_SignOut, firebase_LoginUI] = useFirebase();
  const [dbAuth_Status, dbAuth_Login_Obj] = useDBAuth();

  const renderLimit = useRef(2);
  if (firebase_Status?.login_Status === true && renderLimit.current > 0) {
    // console.log("P1");
    dbAuth_Login_Obj.u_email = firebase_Status?.userInfo_Email;
    renderLimit.current--;
  }
  //  Get Date from DB -----------------------------------------------------------------------------------------

  const [DB_GlobalRankingInfo, setDB_GlobalRankingInfo] = useState([]);
  const getDB_GlobalRankingInfo = () => {
    axios //get__eclipse_gg__eclipse_gg_valorant_playerscore_log__global_PNC
      .post(
        `${REACT_APP_ECLIPSE_API}/get_gg__eclipse_gg_match_log_actual__globalRank_PNC`
      )
      .then((res) => {
        setDB_GlobalRankingInfo(res.data?.[2]);
        console.log(
          "get__eclipse_gg__eclipse_gg_valorant_playerscore_log__global_PNC"
        );
      });
  };
  const [DB_EventInfo, setDB_EventInfo] = useState([]);
  const getDB_EventInfo = () => {
    axios
      .post(`${REACT_APP_ECLIPSE_API}/get_MICC_Dota2__IPP`) //get_MICC_VALORANT__IPP
      .then((res) => {
        setDB_EventInfo(res.data[0]);
        console.log("get_MICC_Dota2__IPP", res.data);
      });
  };

  //-------------------------------------------------------------------------- global variable

  const [cafeLists, setCafeLists] = useState([]);
  const [selectedCafe, setSelectedCafe] = useState("");

  const rows = (
    selectedCafe === ""
      ? DB_GlobalRankingInfo
      : DB_GlobalRankingInfo?.filter((x) => x.cafe === selectedCafe)
  ).map((x, i) => (
    <tr key={i}>
      <td>{i + 1}</td>
      <td>
        <b>{x.global_ranking}</b>
      </td>
      <td>{x.cafe}</td>
      <td>{`${x.personaname} | ${x.player_nickname}`}</td>
      <td>{x.tagline}</td>
      <td>{x.u_contactno}</td>
      <td>{x.u_refno}</td>
      <td>{x?.total_pts}</td>
      <td>{x.total_match_win}</td>
      <td>{x.total_kill}</td>
      <td>{x.total_assists}</td>
      {/* <td>{x.player_puuid}</td> */}
    </tr>
  ));

  //-------------------------------------------------------------------------- func

  const getSelectedCafeRanking = (x) => {
    // console.log("x", x);
  };

  //-------------------------------------------------------------------------- useEffect

  useEffect(() => {
    getDB_GlobalRankingInfo();
    getDB_EventInfo();
  }, []);
  useEffect(() => {
    let result = [...new Set(DB_GlobalRankingInfo.map((x) => x.cafe))];
    result.push("");
    result.sort();
    // console.log("result", result);
    setCafeLists(result);
  }, [DB_GlobalRankingInfo]);

  return (
    <>
      {firebase_Status?.login_Status === false ? (
        <Page_Login />
      ) : dbAuth_Status?.Auth_Login === true ? (
        <>
          <Comp_UserProfile />
          {cafeLists?.map((x) =>
            x !== "" ? (
              <Button
                color="orange"
                variant="outline"
                onClick={() => {
                  setSelectedCafe(x);
                  getSelectedCafeRanking(x);
                }}
              >
                {x}
              </Button>
            ) : (
              <Button
                color="orange"
                variant="filled"
                onClick={() => {
                  setSelectedCafe(x);
                  getSelectedCafeRanking(x);
                }}
              >
                Show Global (Show All)
              </Button>
            )
          )}
          <div style={{ backgroundColor: "white" }}>
            <h1>Event Info</h1>
            <b>{`Dota 2 Season Title: ${DB_EventInfo?.dota2_season__title}`}</b>
            <br />
            <b>{`IPP (rm): ${DB_EventInfo?.IPP}`}</b>
            <br />
            <b>{`Total Duration (hour): ${
              DB_EventInfo?.total_duration_min / 60
            }`}</b>
            <br />
            <br />
            <b>{`Season Start: ${DB_EventInfo?.season_start}`}</b>
            <br />
            <b>{`Season Finish: ${DB_EventInfo?.season_finish}`}</b>
            <br />
            {``}
            <br />
            <br />
            <hr />
            <h1>Global Ranking</h1>
            <Table striped highlightOnHover withBorder withColumnBorders>
              <thead>
                <tr>
                  <th>#no</th>
                  <th>Global Ranking</th>
                  <th>Cafe</th>
                  <th>Game Name</th>
                  <th>Tag Line</th>
                  <th>Contact</th>
                  <th>RefNo</th>
                  <th>Total Points</th>
                  <th>Total Win</th>
                  <th>Total Kills</th>
                  <th>Total Assists</th>
                  {/* <th>Player PUUID</th> */}
                </tr>
              </thead>
              <tbody>{rows}</tbody>
            </Table>
          </div>
        </>
      ) : (
        "Loading..."
      )}
    </>
  );
};

export default Page_CafeEvents;
